// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/pages/DashboardGeral.css */
.slider-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .chart-container {
    position: relative;
    width: 100%; /* Largura do gráfico */
    height: 400px; /* Altura do gráfico */
  }
  
  @media (max-width: 768px) {
    .chart-container {
      height: 300px; /* Altura do gráfico em telas menores */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/dashboardgeral/DashboardGeral.css"],"names":[],"mappings":"AAAA,iCAAiC;AACjC;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,kBAAkB;IAClB,WAAW,EAAE,uBAAuB;IACpC,aAAa,EAAE,sBAAsB;EACvC;;EAEA;IACE;MACE,aAAa,EAAE,uCAAuC;IACxD;EACF","sourcesContent":["/* src/pages/DashboardGeral.css */\n.slider-item {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .chart-container {\n    position: relative;\n    width: 100%; /* Largura do gráfico */\n    height: 400px; /* Altura do gráfico */\n  }\n  \n  @media (max-width: 768px) {\n    .chart-container {\n      height: 300px; /* Altura do gráfico em telas menores */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
