import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { QueryClient, QueryClientProvider } from 'react-query'; // Importação do React Query
import './global.css';



// Criação da instância do QueryClient
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}> {/* Adicionando QueryClientProvider */}
      <App />
    </QueryClientProvider>
  </React.StrictMode>
);
