// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-login {
    display: flex;
    flex: 1 1; /* Garante que o contêiner ocupe todo o espaço disponível */
    justify-content: center; /* Centraliza o formulário horizontalmente */
    align-items: center; /* Centraliza o formulário verticalmente */
    padding: 20px; /* Espaçamento interno opcional */
  }
  
  .form-container {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/login/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAO,EAAE,2DAA2D;IACpE,uBAAuB,EAAE,4CAA4C;IACrE,mBAAmB,EAAE,0CAA0C;IAC/D,aAAa,EAAE,iCAAiC;EAClD;;EAEA;IACE,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,wCAAwC;EAC1C","sourcesContent":[".container-login {\n    display: flex;\n    flex: 1; /* Garante que o contêiner ocupe todo o espaço disponível */\n    justify-content: center; /* Centraliza o formulário horizontalmente */\n    align-items: center; /* Centraliza o formulário verticalmente */\n    padding: 20px; /* Espaçamento interno opcional */\n  }\n  \n  .form-container {\n    width: 100%;\n    max-width: 400px;\n    padding: 20px;\n    background: white;\n    border-radius: 8px;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
