// components/Footer.js
import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import logo from '../assets/logo-oros.png'; // Ajuste o caminho da imagem conforme necessário

const Footer = () => {
  return (
    <footer className="bg-light text-center py-4">
      <Container>
        <Row>
          <Col>
            <Image 
              src={logo} 
              fluid 
              style={{ maxWidth: '100px', height: 'auto' }} // Ajuste o tamanho da imagem
              alt="Logo"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="mb-0">Copyright © 2024 | Powered by Munitec Sistemas</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
