import React, { createContext, useState, useContext, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext();

const isTokenExpired = (token) => {
  const decoded = jwtDecode(token);
  return decoded.exp * 1000 < Date.now(); // Verifica se a data de expiração é menor que a data atual
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const accessToken = localStorage.getItem('munitec_accessToken');
    const refreshToken = localStorage.getItem('munitec_refreshToken');

    if (accessToken && !isTokenExpired(accessToken)) {
      const decodedToken = jwtDecode(accessToken);
      const resourceName = process.env.REACT_APP_KEYCLOACK_CLIENT_NAME; // Nome do client vindo do .env
      const roles = decodedToken.resource_access?.[resourceName]?.roles || [];
      const name = decodedToken.name || decodedToken.userInfo?.name || 'Usuário Desconhecido';

      setUser({ token: accessToken, refreshToken, roles, name });
      console.log('Usuário restaurado do localStorage:', { token: accessToken, roles, name });
    } else {
      console.log('Token expirado ou não encontrado, redirecionando para login');
      setUser(null);
    }
  }, []);

  const login = async (username, password) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_MUNITEC_API_BASE_URL}/as-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const decodedToken = jwtDecode(data.accessToken);
        const roles = decodedToken.resource_access?.node?.roles || [];
        const name = decodedToken.name || decodedToken.userInfo?.name || 'Usuário Desconhecido';

        // Armazenar tokens e informações do usuário
        localStorage.setItem('munitec_accessToken', data.accessToken);
        localStorage.setItem('munitec_refreshToken', data.refreshToken);

        setUser({ token: data.accessToken, refreshToken: data.refreshToken, roles, name });
        console.log('Login bem-sucedido, usuário autenticado:', { token: data.accessToken, roles, name });
      }else {
        if (response.status === 401) {
          throw new Error('Credenciais inválidas');
        } else {
          throw new Error('Erro desconhecido durante o login');
        }
      }
    } catch (error) {
      console.error('Erro durante o login:', error.message);
      setUser(null);
      throw error; // Propagar o erro para o componente Login
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('munitec_accessToken');
    localStorage.removeItem('munitec_refreshToken');
    console.log('Usuário deslogado e tokens removidos do localStorage.');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
