import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_MUNITEC_API_BASE_URL || 'http://192.168.0.14:3001', // Ajuste a URL base
});

// Adiciona o token em todas as requisições
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('munitec_accessToken'); // Obtém o token do localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // Adiciona o token no cabeçalho Authorization
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
