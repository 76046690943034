import React from "react";
import { Container, Alert, Image } from "react-bootstrap";
import sistemaImage from '../../assets/home-image.png'; // Utilize a mesma imagem da página de avaliação

const Agradecimento = () => {
  return (
    <Container className="my-5 text-center">
      <h2>Agradecemos sua Avaliação!</h2>
      <Alert variant="success" className="mt-4">
        Sua avaliação foi registrada com sucesso. Obrigado pelo seu feedback!
      </Alert>
      <Image 
        src={sistemaImage}
        fluid
        className="mx-auto d-block"
        style={{ maxWidth: '30%', height: 'auto' }}
        alt="Descrição do sistema" 
      />
    </Container>
  );
};

export default Agradecimento;
