import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { Container, Card, Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FcComboChart } from "react-icons/fc";
import { FcSms } from "react-icons/fc";
import { FcBusinessman } from "react-icons/fc";


import sistemaImage from '../../assets/home-image.png'; // Ajuste o caminho conforme necessário
import { FcVoicePresentation } from "react-icons/fc";
import './Home.css'; // Importa o CSS específico

const Home = () => {
  const { user } = useAuth();

  const hasRole = (role) => user?.roles?.includes(role);

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4">Bem-vindo ao Sistema</h2>
      <Image 
        src={sistemaImage} // Caminho para sua imagem
        fluid // Para torná-la responsiva
        className="mb-4 mx-auto" // Adiciona margens e centraliza
        style={{ maxWidth: '40%', height: 'auto' }} // Ajusta o tamanho da imagem
        alt="Descrição do sistema" 
      />
      <Row className="mt-4">
        {hasRole('user') && (
          <Col md={4}>
            <Card className="mb-4 text-center"> {/* Alinhamento centralizado */}
              <Card.Body>
                <FcVoicePresentation size={50} className="mb-3" /> {/* Ícone */}
                <Card.Title>Painel de Avaliações</Card.Title>
                <Link to="/painel" className="btn btn-primary w-100">
                  Acessar
                </Link>
              </Card.Body>
            </Card>
          </Col>
        )}
        {hasRole('admin') && (
          <Col md={4}>
            <Card className="mb-4 text-center"> {/* Alinhamento centralizado */}
              <Card.Body>
                <FcBusinessman size={50} className="mb-3" /> {/* Ícone */}
                <Card.Title>Dashboard Administrativo</Card.Title>
                <Link to="/admin" className="btn btn-primary w-100">
                  Acessar
                </Link>
              </Card.Body>
            </Card>
          </Col>
        )}
        {hasRole('user') && (
          <Col md={4}>
            <Card className="mb-4 text-center"> {/* Alinhamento centralizado */}
              <Card.Body>
                <FcComboChart size={50} className="mb-3" /> {/* Ícone */}
                <Card.Title>Dashboard Geral</Card.Title>
                <Link to="/dashboard_geral" className="btn btn-primary w-100">
                  Acessar
                </Link>
              </Card.Body>
            </Card>
          </Col>
        )}
        {hasRole('user') && (
          <Col md={4}>
            <Card className="mb-4 text-center"> {/* Alinhamento centralizado */}
              <Card.Body>
                <FcSms size={50} className="mb-3" /> {/* Ícone */}
                <Card.Title>Acompanhamento de envio</Card.Title>
                <Link to="/status_envio" className="btn btn-primary w-100">
                  Acessar
                </Link>
              </Card.Body>
            </Card>
          </Col>
        )}        
      </Row>
    </Container>
  );
};

export default Home;
