// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;

}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.app-content {
  flex: 1 1; /* Faz o conteúdo crescer e ocupar o espaço disponível */
  display: flex;
  flex-direction: column;
}

footer {
  flex-shrink: 0; /* Garante que o footer não diminua */
}


/* Adicione ao seu CSS global ou específico do Header */
.navbar {
  padding: 0.5rem 1rem; /* Ajuste os valores conforme necessário */
  background: linear-gradient(90deg, #030329, #03488e);

}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,SAAS;EACT,aAAa;EACb,sBAAsB;;AAExB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,SAAO,EAAE,wDAAwD;EACjE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,cAAc,EAAE,qCAAqC;AACvD;;;AAGA,uDAAuD;AACvD;EACE,oBAAoB,EAAE,0CAA0C;EAChE,oDAAoD;;AAEtD","sourcesContent":["html, body {\n  height: 100%;\n  margin: 0;\n  display: flex;\n  flex-direction: column;\n\n}\n\n#root {\n  display: flex;\n  flex-direction: column;\n  min-height: 100%;\n}\n\n.app-content {\n  flex: 1; /* Faz o conteúdo crescer e ocupar o espaço disponível */\n  display: flex;\n  flex-direction: column;\n}\n\nfooter {\n  flex-shrink: 0; /* Garante que o footer não diminua */\n}\n\n\n/* Adicione ao seu CSS global ou específico do Header */\n.navbar {\n  padding: 0.5rem 1rem; /* Ajuste os valores conforme necessário */\n  background: linear-gradient(90deg, #030329, #03488e);\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
