// src/components/PrivateRoute.js

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ children, roles }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simula um pequeno atraso para o carregamento do usuário
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // Ajuste conforme necessário

    return () => clearTimeout(timer);
  }, []);

  // Enquanto o estado do usuário está sendo carregado, não redirecionamos
  if (loading) {
    return <div>Loading...</div>; // Você pode retornar um componente de loading mais apropriado
  }

  // Redireciona para a página de login se o usuário não estiver autenticado
  if (!user) return <Navigate to="/login" />;

  // Verifica se o usuário tem as funções necessárias
  if (roles && !roles.some((role) => user.roles.includes(role))) {
    return <Navigate to="/home" />; // Redireciona para uma página de acesso negado aqui também, se necessário
  }

  return children; // Retorna os filhos se as verificações passarem
};

export default PrivateRoute;
