import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { Form, Button, Alert } from 'react-bootstrap';
import './Login.css'; // Importa o CSS específico

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Limpa mensagens de erro anteriores
  
    try {
      await login(username, password);
      navigate('/home'); // Apenas redireciona se o login for bem-sucedido
      // Forçar reload da página após a navegação
      window.location.reload();
    } catch (err) {
      if (err.message === 'Credenciais inválidas') {
        setError('Credenciais inválidas. Verifique seu usuário e senha.');
      } else {
        setError('Falha na autenticação. Tente novamente mais tarde.');
      }
    }
  };
  return (
    <div className="container-login">
      <div className="form-container">
        <h2>Login</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicUsername">
            <Form.Label>Usuário</Form.Label>
            <Form.Control
              type="text"
              placeholder="Digite seu nome de usuário"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Senha</Form.Label>
            <Form.Control
              type="password"
              placeholder="Digite sua senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="w-100">
            Entrar
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
