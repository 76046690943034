import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, Container, Alert, Image } from "react-bootstrap";
import axios from "axios";
import { FaStar, FaRegStar } from 'react-icons/fa';
import agradecimentoImage from '../../assets/agradecimento.png';

const PaginaAvaliacao = () => {
  const [searchParams] = useSearchParams();
  const atendimento_id = searchParams.get("id");
  const navigate = useNavigate();

  const [rating, setRating] = useState(0);
  const [motivos, setMotivos] = useState([]);
  const [feedback, setFeedback] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showMotivos, setShowMotivos] = useState(false);

  // Verifica o status da avaliação ao carregar a página
  useEffect(() => {
    const verificarStatusAvaliacao = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/status_avaliacao?atendimento_id=${atendimento_id}`
        );
        if (response.data.status === "RESPONDIDO") {
          alert("Essa avaliação já foi respondida. Você será redirecionado.");
          navigate("/agradecimento");
        }
      } catch (error) {
        console.error("Erro ao verificar status da avaliação:", error);
        setErrorMessage("Houve um problema ao verificar o status da avaliação.");
      }
    };

    if (atendimento_id) {
      verificarStatusAvaliacao();
    } else {
      setErrorMessage("ID do atendimento não encontrado.");
    }
  }, [atendimento_id, navigate]);

  const toggleMotivo = (motivo) => {
    setMotivos((prev) =>
      prev.includes(motivo)
        ? prev.filter((m) => m !== motivo)
        : [...prev, motivo]
    );
  };

  const enviarAvaliacao = async (e) => {
    e.preventDefault();
    const dadosAvaliacao = {
      estrelas: rating,
      teve_duvidas: motivos.includes("Dúvidas durante o atendimento"),
      mal_atendido: motivos.includes("Fui mal atendido"),
      problema_nao_resolvido: motivos.includes("Meu problema não foi resolvido"),
      foi_demorado: motivos.includes("Foi demorado"),
      mal_recepcionado: motivos.includes("Fui mal recepcionado"),
      encaminhado_outro_local: motivos.includes("Fui encaminhado para outro local"),
      comentario: feedback,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_MUNITEC_API_BASE_URL}/protected/atualizar_avaliacao?atendimento_id=${atendimento_id}`,
        dadosAvaliacao
      );

      if (response.data.error) {
        setErrorMessage(response.data.error);
        alert(response.data.error);
        navigate("/agradecimento");
        return;
      }

      setSuccessMessage("Avaliação enviada com sucesso! Obrigado pelo feedback.");
      navigate("/agradecimento");
    } catch (error) {
      console.error("Erro ao enviar avaliação:", error);
      const errorMessageToShow = error.response?.data?.error || "Houve um erro ao enviar sua avaliação.";
      alert(errorMessageToShow);
      navigate("/agradecimento");
    }
  };

  const renderStars = () => {
    return Array.from({ length: 5 }, (_, index) => {
      const starValue = index + 1;
      return (
        <span
          key={starValue}
          onClick={() => {
            setRating(starValue);
            setShowMotivos(starValue < 5);
          }}
          style={{ cursor: "pointer", fontSize: "35px" }}
        >
          {starValue <= rating ? (
            <FaStar color="#FFD700" />
          ) : (
            <FaRegStar color="#ccc" />
          )}
        </span>
      );
    });
  };

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Avalie o Atendimento</h2>

      {successMessage && <Alert variant="success">{successMessage}</Alert>}
      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
      <Image
        src={agradecimentoImage}
        fluid
        className="mb-4 mx-auto"
        style={{ maxWidth: '30%', height: 'auto' }}
        alt="Descrição do sistema"
      />

      <Form onSubmit={enviarAvaliacao}>
        <Form.Group controlId="formRating" className="mb-4">
          <Form.Label>Como foi sua experiência com o serviço?</Form.Label>
          <div>{renderStars()}</div>
        </Form.Group>

        {showMotivos && (
          <>
            <h5 className="mb-3">O que faltou para nos dar 5 estrelas?</h5>
            <Row>
              {[
                { label: "Dúvidas durante o atendimento", icon: "❓" },
                { label: "Foi demorado", icon: "⏳" },
                { label: "Fui mal atendido", icon: "👎" },
                { label: "Fui mal recepcionado", icon: "🤷" },
                { label: "Meu problema não foi resolvido", icon: "⚠️" },
                { label: "Fui encaminhado para outro local", icon: "➡️" },
              ].map(({ label, icon }) => (
                <Col key={label} xs={12} md={6} className="mb-2">
                  <Form.Check
                    type="checkbox"
                    id={`motivo-${label}`}
                    label={
                      <>
                        <span className="me-2">{icon}</span>
                        {label}
                      </>
                    }
                    value={label}
                    checked={motivos.includes(label)}
                    onChange={() => toggleMotivo(label)}
                  />
                </Col>
              ))}
            </Row>

          </>
        )}

        <Form.Group controlId="formFeedback" className="mt-4">
          <Form.Label>Deixe elogio, sugestão ou crítica (opcional):</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </Form.Group>

        <div className="text-center mt-4">
          <Button variant="primary" type="submit">
            Enviar Avaliação
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default PaginaAvaliacao;
