import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Login from './pages/login/Login';
import Home from './pages/home/Home';
import Avaliacao from './pages/avaliacao/Avaliacao';
import DashboardGeral from './pages/dashboardgeral/DashboardGeral';
import Admin from './pages/admin/Admin';
import Painel from './pages/painel/Painel';
import Header from './components/Header';
import PrivateRoute from './components/PrivateRoute';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/Footer'; // Importe o componente Footer
import './global.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StatusEnvio from './pages/statusenviomensagem/StatusEnvioMensagem';
import Agradecimento from './pages/agradecimento/Agradecimento';





function AppContent() {
  const location = useLocation(); // Hook para pegar a rota atual
  const hideHeaderRoutes = ["/login", "/avaliacao", "/agradecimento"]; // Rotas que não devem exibir o Header
  const hideFooterRoutes = ["/admin", "/status_envio", "/dashboard_geral"]; // Rotas que ocultam o Footer
  const currentPath = location.pathname;

  return (
    <div className="app-content">

      {/* Renderiza o Header apenas se a rota atual não estiver em hideHeaderRoutes */}
      {!hideHeaderRoutes.includes(currentPath) && <Header />}

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/avaliacao" element={<Avaliacao />} />
        <Route path="/agradecimento" element={<Agradecimento />} />

        {/* Usando PrivateRoute para proteger as rotas */}

        <Route
          path="/home"
          element={
            <PrivateRoute roles={['user', 'admin']}>
              <Home />
            </PrivateRoute>
          }
        />


        <Route
          path="/dashboard_geral"
          element={
            <PrivateRoute roles={['user', 'admin']}>
              <DashboardGeral />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute roles={['admin']}>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="/painel"
          element={
            <PrivateRoute roles={['user']}>
              <Painel />
            </PrivateRoute>
          }
        />
        <Route
          path="/status_envio"
          element={
            <PrivateRoute roles={['admin']}>
              <StatusEnvio />
            </PrivateRoute>
          }
        />
      </Routes>

      {/* Renderiza o Footer apenas se a rota atual não estiver em hideFooterRoutes */}
      {!hideFooterRoutes.includes(currentPath) && <Footer />}

    </div>

  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
